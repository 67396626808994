<template>
  <div>
    <el-form
      :model="obj"
      ref="dynamicValidateForm"
      class="demo-dynamic"
    >
      <el-row class="gears-row">
        <el-col :span="3"
          ><div style="text-align: initial">考核提成：</div></el-col
        >
        <el-col :span="21">
          <el-row v-if="this.obj.sectionReq.length <= 0">
            <el-col :span="18">
              <el-row>
                <el-col :span="8"
                  ><div style="text-align: initial">
                    档位 {{ indexName }}
                  </div></el-col
                >
                <el-col :span="16">
                  <div
                    v-if="isClose"
                    class="close-gears-button"
                    @click="closeGears"
                  >
                    关闭档位
                  </div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6"
              ><div class="add-gears" @click="addCondition(obj.sectionReq)">
                添加条件<i class="el-icon-plus"></i></div
            ></el-col>
          </el-row>
          <div v-for="(item, index) in obj.sectionReq" :key="index">
            <el-row v-if="index === 0">
              <el-col :span="18">
                <el-row>
                  <el-col :span="8"
                    ><div style="text-align: initial">
                      档位 {{ indexName }}
                    </div></el-col
                  >
                  <el-col :span="16">
                    <div
                      v-if="isClose"
                      class="close-gears-button"
                      @click="closeGears"
                    >
                      关闭档位
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="6"
                ><div class="add-gears" @click="addCondition(obj.sectionReq)">
                  添加条件<i class="el-icon-plus"></i></div
              ></el-col>
            </el-row>
            <el-row v-else>
              <el-col :span="18"
                ><div style="text-align: initial">
                  条件关系 {{ obj.sectionReq[index-1].typeValue }}
                </div></el-col
              >
              <el-col :span="6"
                ><div class="del-gears" @click="delCondition(index)">
                  删除条件<i class="el-icon-minus"></i></div
              ></el-col>
            </el-row>
            <el-row class="condition-row">
              <el-col :span="4">
                <div style="text-align: initial">条件{{ index + 1 }}</div>
              </el-col>
              <el-col :span="4">
                <div>
                  <el-form-item
                    :prop="'sectionReq.' + index + '.start'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入起始值',
                        trigger: ['blur','change'],
                      },
                    ]"
                    ><el-input
                      v-model="item.start"
                      placeholder="请输入内容"
                    ></el-input
                  ></el-form-item>
                </div>
              </el-col>
              <el-col :span="8">
                <div>&lt;= {{ item.caliber }} &lt;=</div>
              </el-col>
              <el-col :span="4">
                <div>
                  <el-form-item
                    :prop="'sectionReq.' + index + '.end'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入结束值',
                        trigger: ['blur','change'],
                      },
                    ]"
                    ><el-input
                      v-model="item.end"
                      placeholder="请输入内容"
                    ></el-input
                  ></el-form-item>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-if="this.obj.sectionReq.length > 0">
            <el-row class="result-row">
              <el-col :span="4"
                ><div style="text-align: initial">统计结果</div></el-col
              >
              <el-col :span="6"
                ><div style="text-align: initial">奖励金额(元/单)</div></el-col
              >
              <el-col :span="4"
                ><div>
                  <el-form-item
                    prop="amountValue"
                    :rules="[
                      {
                        required: true,
                        message: '请输入返回值',
                        trigger: ['blur','change'],
                      },
                    ]"
                    ><el-input
                      v-model="obj.amountValue"
                      placeholder="请输入内容"
                    ></el-input
                  ></el-form-item></div
              ></el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <el-divider />
  </div>
</template>

<script>
export default {
  name: "gears",
  props: {
    indexName: {
      type: String,
      default: "1",
    },
    isClose: {
      type: Boolean,
      default: true,
    },
    obj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      list: [],
      input: "",
    };
  },
  mounted() {},
  methods: {
    addCondition(list) {
      this.$emit("addCondition", list);
    },
    delCondition(index) {
      this.obj.sectionReq.splice(index, 1);
    },
    closeGears() {
      this.$emit("closeGears");
    },
    verificationForm(fun) {
      return this.$refs['dynamicValidateForm'].validate(fun);
    },
  },
};
</script>

<style scoped>
.el-row {
  line-height: 40px;
}
.condition-row {
  border-bottom: #999 dashed 1px;
  padding-bottom: 15px;
}
.add-gears {
  color: #2e984b;
  cursor: pointer;
}
.del-gears {
  color: rgb(2178, 0, 27);
  cursor: pointer;
}
.result-row {
  padding-top: 10px;
}
.close-gears-button {
  width: 90px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  background-color: rgb(245, 154, 35);
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
/*  */
</style>