<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="4">
        <div>
          <el-button
            style="background-color: rgba(61, 203, 203, 1)"
            type="primary"
            ><i class="el-icon-timer el-icon--right"></i
            >平台计费规则设置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="11">
        <itemVue @charge="chargeItem" />
      </el-col>
      <el-col :span="11">
        <caliberVue ref="caliberVue" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row :gutter="20">
      <el-col :span="11">
        <logicVue ref="logicVue" />
      </el-col>
      <el-col :span="11">
        <billingTemplateVue />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import itemVue from "./item.vue";

import caliberVue from "./caliber.vue";

import logicVue from "./logic.vue";

import billingTemplateVue from "./billingTemplate.vue";

export default {
  data() {
    return {};
  },
  mounted() {
    // this.loadList();
    
  },
  methods: {
    chargeItem(id,mode){
      console.log(id,mode);
      this.$refs.caliberVue.loadTable(id);
      this.$refs.logicVue.changeItemId(id,mode);
    }

  },
  components: { itemVue, caliberVue, logicVue, billingTemplateVue },
};
</script>

<style>
</style>