<template>
  <div class="search">
    <div style="text-align: initial; margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-plus" @click="addItem()"
        >新增统计项目</el-button
      >
      <p id="bd">
        <img src="@/static/system/u977.svg" />
        <span style="color: #565c5f">当前选择:</span>
        <span id="select">{{ currentSelection }}</span>
        <a id="navname" v-if="!isHidden" @click="deselect">取消选择 </a>
      </p>
    </div>
    <el-table
      id="table"
      :data="list"
      height="448"
      :header-cell-style="tableHeader"
      @row-click="getDetails"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
    >
      <el-table-column
        type="index"
        label="编号"
        width="50"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <div>
            <el-radio v-model="radio" :label="scope.row.id">{{
              radioTest
            }}</el-radio>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="id"
        label="统计项目ID"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="itemName"
        label="统计项目名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="mode"
        label="项目类型"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ options[scope.row.mode].name }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="createTime"
        label="添加时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link
            type="primary"
            @click="editItem(scope.row)"
            :underline="false"
            size="small"
            >修改
          </el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="添加项目"
      :show-close="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleadd"
      @close="clearData"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="12" :sm="16" :md="12">
          <el-form
            :model="addItemParam"
            ref="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="项目名称:" prop="itemName">
              <el-input v-model="addItemParam.itemName"></el-input>
            </el-form-item>
            <el-form-item label="项目类型:" prop="mode">
              <el-select
                style="width: 100%"
                v-model="addItemParam.mode"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.datavalue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="cancel()">取消</el-button>
        <el-button type="primary" @click="add">添加</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改项目"
      :show-close="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleupdate"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="12" :sm="16" :md="12">
          <el-form
            :model="updateItemParam"
            ref="updateForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="统计项目ID:" prop="id">
              <el-input disabled v-model="updateItemParam.id"></el-input>
            </el-form-item>
            <el-form-item label="项目名称:" prop="itemName">
              <el-input v-model="updateItemParam.itemName"></el-input>
            </el-form-item>
            <el-form-item label="项目类型:" prop="mode">
              <el-select
                style="width: 100%"
                v-model="updateItemParam.mode"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.datavalue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="cancel()">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </span>
    </el-dialog>

    <div>
      <template>
        <el-pagination
          class="sx"
          background
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
    </div>
  </div>
</template>
<script>
import dictionaryService from "@/service/moudules/dictionaryService.js";

export default {
  name: "item",
  data() {
    return {
      radio: "",
      radioTest: "",
      currentSelection: "",
      dialogVisibleadd: false,
      dialogVisibleupdate: false,
      isHidden: true,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
      },
      itemMode: process.env.VUE_APP_ITEM_CODE,
      item: "",
      list: [],
      updateItemParam: {
        id: "",
        itemName: "",
        mode: "",
      },
      addItemParam: {
        itemName: "",
        mode: "", 
      },
      rules: {
        itemName: [
          { required: true, message: "请输入项目名称", trigger: "blur" },
        ],
        mode: [
          { required: true, message: "请选择项目类型", trigger: "change" },
        ],
      },
      total: 0,
      options: [
        // {
        //   value: 0,
        //   label: "比例",
        // },
        // {
        //   value: 1,
        //   label: "范围",
        // },
        // {
        //   value: 2,
        //   label: "固定值",
        // },
      ],
    };
  },
  mounted() {
    this.loadList();
    this.loadState();
  },
  methods: {
    clearData() {
        this.$refs["ruleForm"].resetFields();
    },
    // 加载状态字典数据
    loadState() {
      dictionaryService
        .findDictionariesItemList(this.itemMode)
        .then((res) => {
          this.options = res;
        })
        .catch((err) => {});
    },
    addItem() {
      this.dialogVisibleadd = true;
    },
    cancel() {
      this.dialogVisibleupdate = false;
      this.dialogVisibleadd = false;
      this.loadList();
    },
    add() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.saveItem();
          this.dialogVisibleadd = false;
        } else {
          return false;
        }
      });
    },
    confirm() {
      this.$refs["updateForm"].validate((valid) => {
        if (valid) {
          this.updateItem();
          this.dialogVisibleupdate = false;
        } else {
          return false;
        }
      });
    },
    async updateItem() {
      await this.api.billingApi
        .updateItem(this.updateItemParam)
        .then((response) => {
          if (response.data.data == true) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.loadList();
            this.getDetails(this.updateItemParam);
          } else {
            this.$message.error("修改失败");
          }
        });
    },
    async saveItem() {
      await this.api.billingApi.saveItem(this.addItemParam).then((response) => {
        if (response.data.data == true) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.loadList();
        } else {
          this.$message.error("添加失败");
        }
      });
    },
    editItem(row) {
      this.updateItemParam = { ...row };
      this.updateItemParam.mode = String(row.mode);
      this.dialogVisibleupdate = true;
    },
    async loadList() {
      console.log("加载数据！");
      let response = await this.api.billingApi.listPageItem(this.queryForm);
      this.list = response.data.data.records;
      this.total = response.data.data.total;
    },
    getDetails(row) {
      this.radio = row.id;
      this.currentSelection = row.itemName;
      this.$emit("charge", row.id,row.mode);
      this.isHidden = false;
    },
    deselect() {
      this.currentSelection = "";
      this.radio = "";
      this.isHidden = true;
      this.$emit("charge", "");
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    pageChange(val) {
      this.queryForm.pageNo = val;
      this.loadList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {},
};
</script>
<style scoped>
.tabbox {
  text-align: left;
}
#table {
  /* padding: 20px 20px 0px 20px; */
  background-color: rgba(29, 144, 255, 0);
}
.el-dialog {
  text-align: left;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
}
.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}
.sx {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

/* ------------------- */

#bd {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  background-color: #e6f7ff;
  border-radius: 5px;
  border: 1px solid #1d90ff;
  font-size: 16px;
  margin: 20px 0 10px 0;
}

#bd > span {
  vertical-align: middle;
  margin-right: 20px;
}

#bd > img {
  vertical-align: middle;
  margin: 0 5px 0 10px;
}
</style>
