<template>
  <div id="back" style="text-align: initial">
    <el-row :gutter="20" class="elrow">
      <el-col :span="6"
        ><span class="blurspan" style="color: #f59a23">举例说明</span></el-col
      >
    </el-row>
    <el-row :gutter="20" class="elrow">
      <el-col :span="3"><span class="blurspan">统计项目:</span></el-col>
      <el-col :span="6"><span class="blurspan">考核提成</span></el-col>
    </el-row>
    <el-row :gutter="20" class="elrow">
      <el-col :span="3"><span class="blurspan">统计口径:</span></el-col>
      <el-col :span="14"
        ><span class="blurspan"
          >完成订单数、实际出勤天数、出勤率、奖励金额(元)</span
        ></el-col
      >
    </el-row>
    <el-row :gutter="20" class="elrow">
      <el-col :span="3"><span class="blurspan">设置逻辑:</span></el-col>
      <el-col :span="21">
        <el-table
          id="table"
          border
          :data="list"
        >
          <el-table-column
            prop="condition"
            label="条件"
            :show-overflow-tooltip="true"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="caliber"
            label="统计口径"
            :show-overflow-tooltip="true"
            align="center"
            width="140"
          >
            <template slot-scope="scope">
              <span v-html="scope.row.caliber"></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="gearOne"
            label="档位一"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <span v-html="scope.row.gearOne"></span>
            </template>
          </el-table-column>
          <el-table-column
            prop="gearTwo"
            label="档位二"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <span v-html="scope.row.gearTwo"></span>
            </template>
          </el-table-column> </el-table
      ></el-col>
    </el-row>
    <el-row class="elrow">
      <el-col :span="3"
        ><p><span class="blurspan">统计结果:</span></p></el-col
      >
      <el-col :span="21"
        ><p>
          场景1：配送员当月完成300单，实际出勤天数10天，准时率无论多少，则考核提成=0元
        </p>
        <p>
          场景2：配送员当月完成600单，实际出勤天数10天，准时率92%，则考核提成=0元
        </p>
        <p>
          场景3：配送员当月完成600单，实际出勤天数10天，准时率96%，则考核提成=600*0.1=60元
        </p>
        <p>
          场景4：配送员当月完成600单，实际出勤天数10天，准时率98%，则考核提成=600*0.3=180元
        </p></el-col
      >
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          condition: "条件一",
          caliber: "完成订单数",
          gearOne:
            "<span style ='color:red'>500</span><=完成订单数<=<span style ='color:red'>9999</span>",
          gearTwo:
            "<span style ='color:red'>500</span><=完成订单数<=<span style ='color:red'>9999</span>",
        },
        {
          condition: "条件二",
          caliber: "实际出勤天数",
          gearOne:
            "<span style ='color:red'>5</span><=实际出勤天数<=<span style ='color:red'>31</span>",
          gearTwo:
            "<span style ='color:red'>5</span><=实际出勤天数<=<span style ='color:red'>31</span>",
        },
        {
          condition: "条件三",
          caliber: "准时率",
          gearOne:
            "<span style ='color:red'>95%</span><=准时率<<span style ='color:red'>97%</span>",
          gearTwo:
            "<span style ='color:red'>97%</span><=准时率<=<span style ='color:red'>100%</span>",
        },
        {
           condition: "统计结果",
          caliber: "奖励金额（元/单）",
          gearOne: "奖励金额（元/单）=<span style ='color:red'>0.1</span>",
          gearTwo: "奖励金额（元/单）=<span style ='color:red'>0.3</span>",
        },
      ],
    };
  },

  mounted: function () {},
  created: function () {},
  methods: {},
  components: {},
};
</script>
<style scoped>
#back {
  height: 575px;
  background-color: white;
  padding: 15px 20px 100px 20px;
}

#table {
  background-color: rgba(29, 144, 255, 0);
}

#page {
  float: right;
  margin-top: 15px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.tablebox {
  text-align: left;
}

.blurspan:hover {
  color: dodgerblue !important;
}

.elrow {
  padding: 5px 0;
}

.colorspan {
  color: red;
}

.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>
