<template>
  <div class="body">
    <div v-if="queryForm.mode == 1">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="6"
          ><div style="text-align: initial; color: #1d90ff">
            统计逻辑
          </div></el-col
        >
        <el-col :span="6"
          ><div class="add-gears-button" @click="addGears">
            添加档位
          </div></el-col
        >
      </el-row>
      <el-divider />
      <div class="logic">
        <gearsVue
          ref="gears"
          v-for="(item, index) in list"
          :key="index"
          :indexName="String(index + 1)"
          :obj="item"
          :isClose="index != 0"
          @addCondition="addConditionButton"
          @closeGears="closeGears(index)"
        />
      </div>
      <div>
        <el-dialog
          title="添加逻辑条件"
          :visible.sync="caliberDialog"
          @close="clearData"
        >
          <el-divider />
          <div style="padding: 20px">
            <el-table
              :data="caliberList"
              border
              height="289"
              @row-click="getDetails"
              style="width: 100%"
            >
              <el-table-column
                prop="id"
                label="选择"
                :show-overflow-tooltip="true"
                align="center"
                width="50"
              >
                <template slot-scope="scope">
                  <div>
                    <el-radio v-model="gearsItem.id" :label="scope.row.id">{{
                      radioTest
                    }}</el-radio>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="variable"
                label="口径字段"
                :show-overflow-tooltip="true"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="caliber"
                :show-overflow-tooltip="true"
                align="center"
                label="口径名称"
              >
              </el-table-column>
              <el-table-column
                prop="remark"
                :show-overflow-tooltip="true"
                align="center"
                label="备注"
              >
              </el-table-column>
            </el-table>
            <p></p>
            <el-pagination
              small
              @size-change="handleSizeChange"
              @current-change="pageChange"
              :page-size="queryForm.pageSize"
              layout="prev, pager, next"
              :total="total"
            >
            </el-pagination>
            <el-form
              style="text-align: initial; padding-top: 20px"
              ref="gearsItem"
              :model="gearsItem"
              label-width="130px"
            >
              <el-form-item label="上下条件关系：" v-if="listData.length > 0">
                <el-select
                  v-model="gearsItem.andOr"
                  placeholder="请选择活动区域"
                >
                  <el-option label="与" :value="0"></el-option>
                  <el-option label="或" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-divider />
          <span>
            <el-button @click="caliberDialog = false">取 消</el-button>
            <el-button type="primary" @click="addCondition">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <div v-if="queryForm.mode == 0">
      <el-row type="flex" justify="center">
        <el-col>
          <el-form
            :inline="true"
            ref="paginationForm"
            :model="proportion"
            :rules="rules"
            label-width="110px"
          >
            <el-form-item label="请选择口径:" prop="variable">
              <el-select v-model="proportion.variable">
                <el-option
                  v-for="item in caliberList"
                  :key="item.id"
                  :label="item.caliber"
                  :value="item.variable"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="比例值:" prop="proportion">
              <el-input
                type="number"
                style="width: 90px"
                v-model="proportion.proportion"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col style="line-height: 40px; text-align: initial" :md="5">
          %
        </el-col>
      </el-row>
    </div>
    <div v-if="queryForm.mode == 2">
      <el-row type="flex" justify="center">
        <el-col :md="7">
          <el-form
            :model="queryForm"
            :rules="rules"
            ref="fixedForm"
            label-width="100px"
          >
            <el-form-item label="固定值:" prop="fixed">
              <el-input type="number" v-model="queryForm.fixed"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="15"
        ><div><el-button @click="resetData">重置</el-button></div></el-col
      >
      <el-col :span="5"
        ><div>
          <el-button type="primary" @click="submitCondition">保存</el-button>
        </div></el-col
      >
    </el-row>
  </div>
</template>

<script>
import { forEach } from "mathjs";

import gearsVue from "./gears.vue";

export default {
  name: "logic",
  data() {
    return {
      gearsItem: {
        id: "",
        resource: "",
        andOr: 0,
      },
      radio: "",
      radioTest: "",
      caliberDialog: false,
      listData: [],
      rules: {
        proportion: [
          {
            required: true,
            message: "请输入比例值",
            trigger: ["blur", "change"],
          },
        ],
        variable: [
          {
            required: true,
            message: "请选择口径",
            trigger: ["blur", "change"],
          },
        ],
        fixed: [
          {
            required: true,
            message: "请输入固定值",
            trigger: ["blur", "change"],
          },
        ],
      },
      queryForm: {
        mode: "",
        itemId: "",
        fixed: 0,
        pageNo: 1,
        pageSize: 5,
      },
      caliberList: [],
      total: 0,
      proportion: {},
      list: [],
    };
  },
  mounted() {},
  methods: {
    clearData() {
      this.gearsItem = this.$options.data().gearsItem;
    },
    pageChange(val) {
      this.queryForm.pageNo = val;
      this.loadCaliberList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.loadCaliberList();
      console.log(`每页 ${val} 条`);
    },
    // 加载口列表
    async changeItemId(id, mode) {
      this.queryForm.itemId = id;
      this.queryForm.mode = mode;
      let loading = this.openloading();
      await this.loadCaliberList();
      this.closeloading(loading);
    },
    openloading() {
      return this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    closeloading(loading) {
      loading.close();
    },
    async resetData() {
      let loading = this.openloading();
      await this.loadCaliberList();
      this.closeloading(loading);
    },
    async loadCaliberList() {
      await this.api.billingApi
        .listPageCaliberList(this.queryForm)
        .then((response) => {
          // hhtp 成功
          if (response.data.data != null) {
            this.caliberList = response.data.data.records;
            this.total = response.data.data.total;
          } else {
            this.caliberList = [];
            this.total = 0;
          }
        });
      await this.api.billingApi
        .getBilling({ itemId: this.queryForm.itemId })
        .then((res) => {
          this.list = [];
          this.queryForm.fixed = null;
          this.proportion = {};
          let data = res.data;
          if (data.data != null) {
            let fixed = res.data.data.fixed;
            if (fixed != null && fixed != undefined) {
              this.queryForm.fixed = fixed;
            }
            let proportion = data.data.proportion;
            if (proportion != null && proportion != undefined) {
              this.proportion = proportion;
            }
            let range = data.data.range;
            if (range != null && range != undefined) {
              for (let i in range) {
                this.$set(range[i], "amountValue", range[i].amount / 100);
                let sectionReq = range[i].sectionReq;
                for (let k in sectionReq) {
                  this.$set(
                    sectionReq[k],
                    "typeValue",
                    sectionReq[k].andOr == 0 ? "与" : "或"
                  );
                }
              }
              this.list = range;
            }
          }
        });
    },
    getDetails(row) {
      this.gearsItem.id = row.id;
      this.gearsItem.variable = row.variable;
      this.gearsItem.caliber = row.caliber;
    },
    addConditionButton(listData) {
      this.caliberDialog = true;
      this.listData = listData;
    },
    addCondition() {
      if (
        this.gearsItem.id == null ||
        this.gearsItem.id == undefined ||
        this.gearsItem.id == ""
      ) {
        this.$message({
          message: "请选择口径",
          type: "warning",
        });
        return;
      }
      let item = { ...this.gearsItem };
      if (this.listData.length > 0) {
        this.listData[this.listData.length - 1].typeValue =
          this.gearsItem.andOr == 0 ? "与" : "或";
        this.listData[this.listData.length - 1].andOr = this.gearsItem.andOr;
      }
      this.listData.push(item);
      this.caliberDialog = false;
    },
    async submitCondition() {
      let range = this.list;
      let flag = true;
      if (this.queryForm.mode == 1) {
        // 验证表单
        let gears = this.$refs.gears;
        for (let i in gears) {
          gears[i].verificationForm((valid) => {
            if (!valid) {
              flag = false;
            }
          });
        }

        for (let i in range) {
          range[i].amount = range[i].amountValue * 100;
        }
      } else if (this.queryForm.mode == 0) {
        this.$refs["paginationForm"].validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      } else if (this.queryForm.mode == 2) {
        this.$refs["fixedForm"].validate((valid) => {
          if (!valid) {
            flag = false;
          }
        });
      }
      if (!flag) {
        return;
      }
      let loading = this.openloading();
      let addConditionParam = {
        itemId: this.queryForm.itemId,
        type: this.queryForm.mode,
        range: range,
        proportion: this.proportion,
        fixed: this.queryForm.fixed,
      };
      await this.api.billingApi
        .addCondition(addConditionParam)
        .then((response) => {
          this.closeloading(loading);
          if (response.data.data != null && response.data.data != undefined) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.loadCaliberList();
          } else {
            this.$message.error("保存失败");
          }
        });
    },
    addGears() {
      this.list.push({ sectionReq: [] });
    },
    closeGears(index) {
      this.list.splice(index, 1);
    },
  },
  components: { gearsVue },
};
</script>

<style scoped>
.body {
  padding: 20px;
  background-color: #fff;
  height: 650px;
}
.el-col el-col-11 {
  height: 836px;
}
.logic {
  overflow: overlay;
  max-height: 480px;
}
.add-gears-button {
  color: #fff;
  padding: 8px;
  width: 80px;
  border-radius: 5px;
  background-color: rgba(61, 203, 203, 1);
  border-color: rgba(61, 203, 203, 1);
  cursor: pointer;
}
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>