<template>
  <div class="search">
    <div style="text-align: initial">
      统计口径：
      <el-divider></el-divider>
    </div>
    <div style="text-align: initial; margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-plus" @click="addCaliber()"
        >添加统计口径</el-button
      >
    </div>
    <el-table
      id="table"
      :data="list"
      height="437"
      :header-cell-style="tableHeader"
      :cell-style="tableCell"
      :default-sort="{ prop: 'timestamp', order: 'descending' }"
      border
    >
      <el-table-column
        type="index"
        label="编号"
        width="50"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="variable"
        label="口径字段"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="caliber"
        label="口径名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="添加时间"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-link
            type="primary"
            @click="editCaliber(scope.row)"
            :underline="false"
            size="small"
            >修改
          </el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="添加口径"
      :show-close="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleadd"
      @close="clearData"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="12" :sm="16" :md="12">
          <el-form
            :model="addCaliberParam"
            ref="ruleForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="口径变量:" prop="variable">
              <el-input v-model="addCaliberParam.variable"></el-input>
            </el-form-item>
            <el-form-item label="口径名称:" prop="caliber">
              <el-input v-model="addCaliberParam.caliber"></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="addCaliberParam.remark"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="cancel()">取消</el-button>
        <el-button type="primary" @click="add">添加</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改口径"
      :show-close="true"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleupdate"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="12" :sm="16" :md="12">
          <el-form
            :model="updateCaliberParam"
            ref="updateForm"
            :rules="rules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="口径变量:" prop="variable">
              <el-input v-model="updateCaliberParam.variable"></el-input>
            </el-form-item>
            <el-form-item label="口径名称:" prop="caliber">
              <el-input v-model="updateCaliberParam.caliber"></el-input>
            </el-form-item>
            <el-form-item label="备注:" prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="updateCaliberParam.remark"
              >
              </el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="cancel()">取消</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </span>
    </el-dialog>

    <div>
      <template>
        <el-pagination
          class="sx"
          background
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "item",
  data() {
    return {
      radio: "",
      dialogVisibleadd: false,
      dialogVisibleupdate: false,
      queryForm: {
        itemId: "",
        pageNo: 1,
        pageSize: 10,
      },
      updateCaliberParam: {
        id: "",
        itemId: "",
        caliber: "",
        variable: "",
        remark: "",
      },
      addCaliberParam: {
        itemId: "",
        caliber: "",
        variable: "",
        remark: "",
      },
      rules: {
        caliber: [
          { required: true, message: "请输入口径名称", trigger: "blur" },
        ],
        variable: [
          { required: true, message: "请输入口径变量", trigger: "blur" },
        ],
      },
      total: 0,
      // 数集合
      list: [],
    };
  },
  mounted() {},
  methods: {
    clearData() {
      this.$refs["ruleForm"].resetFields();
    },
    addCaliber() {
      this.dialogVisibleadd = true;
    },
    editCaliber(row) {
      this.updateCaliberParam = row;
      this.dialogVisibleupdate = true;
    },
    cancel() {
      this.loadList();
      this.dialogVisibleadd = false;
      this.dialogVisibleupdate = false;
    },
    confirm() {
      this.$refs["updateForm"].validate((valid) => {
        if (valid) {
          this.updateCaliber();
          this.dialogVisibleupdate = false;
        } else {
          return false;
        }
      });
    },
    add() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.submitAddCaliber();
          this.dialogVisibleadd = false;
        } else {
          return false;
        }
      });
    },
    async updateCaliber() {
      await this.api.billingApi
        .updateCaliber(this.updateCaliberParam)
        .then((response) => {
          if (response.data.data != null && response.data.data != "") {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          } else {
            this.$message.error("修改失败");
          }
        });
    },
    async submitAddCaliber() {
      await this.api.billingApi
        .addCaliber(this.addCaliberParam)
        .then((response) => {
          if (response.data.data != null) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
          } else {
            this.$message.error(response.data.msg);
          }
        });
      this.loadList();
    },
    loadTable(id) {
      this.queryForm.itemId = id;
      this.addCaliberParam.itemId = id;
      this.loadList();
    },
    async loadList() {
      await this.api.billingApi
        .listPageCaliberList(this.queryForm)
        .then((response) => {
          // hhtp 成功
          if (response.data.data != null) {
            this.list = response.data.data.records;
            this.total = response.data.data.total;
          } else {
            this.list = [];
            this.total = 0;
          }
        });
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    pageChange(val) {
      this.queryForm.pageNo = val;
      this.loadList();
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.queryForm.pageNo = 1;
      this.loadList();
      console.log(`每页 ${val} 条`);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  components: {},
};
</script>
<style scoped>
.tabbox {
  text-align: left;
}
#table {
  /* padding: 20px 20px 0px 20px; */
  background-color: rgba(29, 144, 255, 0);
}
.el-dialog {
  text-align: left;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
}
.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.search {
  background-color: white;
  padding: 15px 20px 100px 20px;
  overflow: hidden;
}
.sx {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

/* ------------------- */
</style>
